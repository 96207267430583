<!--电能设置--基本设置---->
<template>
  
  <div id="energyActualUsage" v-loading="loading" :element-loading-text="$t('alarm.a0')">
     <!-- 页面标题 -->
    <div class="header-title">
      <span>{{ $t("energy.enedata019") }}</span>
    </div>

    <div class="header">
      <div class="header-left">
         <div class="header-condition">
          <span>{{ $t("energy.enedata002") }}</span>
          <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
              <a-radio-button  v-for="(itme, index) in this.reportTypeList"
                  :key="index"
                  :value="itme.no"> {{ itme.text }}</a-radio-button>
          </a-radio-group>
        </div>
        <div class="header-condition" v-if="rpt=='1'">
           <span>{{ $t("energy.enedata020") }}</span>
          <a-select v-model="baseUnit" style="width: 150px" @change="baseUnitChange">
             <a-select-option
                v-for="(itme, index) in this.baseUnitNormList"
                :key="index"
                :value="itme.no"
                >
                {{ itme.text }}
              </a-select-option>
          </a-select>
        </div>
        <div class="header-condition" v-if="rpt=='1'">
           <span>{{ $t("energy.enedata021") }}</span>
          <a-select v-model="cctype" style="width: 150px" @change="ccTypeChange">
             <a-select-option
                v-for="(itme, index) in this.conversionCoefficientTypes"
                :key="index"
                :value="itme.no"
                >
                {{ itme.text }}
              </a-select-option>
          </a-select>
        </div>
        <div class="header-condition">
          <span>{{ $t("energy.enedata005") }}</span>
          <a-select v-model="year" style="width: 120px" @change="yearChange">
              <a-select-option
                  v-for="(itme, index) in this.yearList"
                  :key="index"
                  :value="itme"
                  >
                  {{ itme }}
              </a-select-option>
          </a-select>
        </div>
        <div class="header-condition">
            <span>{{$t("energy.enedata398")}}</span>
            <a-switch v-model="msel" @change="mselChange" :style="{'width':'60px','margin-top':'5px'}" />
        </div>
        <div class="header-condition">
            <span>{{$t("energy.enedata399")}}</span>
            <a-select v-model="month" style="width: 120px" :disabled="defaultStatistic==0" >
            <a-select-option
                v-for="(itme, index) in this.monthList"
                :key="index"
                :value="itme.no"
                >
                {{ itme.text }}
                </a-select-option>
            </a-select>
        </div>

      </div>
      
      <a-button type="primary" style="width: 120px"  @click="search()"> {{ $t("energy.enedata006") }} </a-button>
    </div>
    <div class="main" ref="main">
         <!-- :scroll="tscroll" -->
         <a-table 
         :columns="columns" 
         :data-source="data"
         :pagination="pagination"  
         :scroll="tscroll"
         bordered size="small" >
        </a-table>
    </div>
    <div class="footer">
        <div v-if="rpt=='1'" style="font-size: 14px;"> {{ $t("energy.enedata022") }}</div>
        <a-button type="primary" :disabled="data==null" @click="csvout"> {{ $t("energy.enedata042") }} </a-button>
    </div>

  </div>
</template>



<script>
import { getEnergyActualUsageData , downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment";

export default {
  name: "energyActualUsage",
  data() {
    
    return {
      loading: false,
      data:null,
      columns:null,
      pagination:false,
      selectedRowKeys:[],
      tscroll:{x:1000,y:280},
      baseUnitNormList:[],
      conversionCoefficientTypes:[],
      reportTypeList:[],
      yearList:[],
      baseUnit:'',
      cctype:'',
      rpt:'',
      year:0,
      msel:false,
      defaultStatistic:0,
      month:"1",
      monthList:[],
      monthMap:null,
      fiscalYear:undefined,
      }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:'checkbox',
      };
    },
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.data = [];
        this.columns = [];
        this.initPage();
    }
  },
  mounted() { 
    //this.initPage();
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods:{
     mselChange(){
      console.log("mselChange",this.msel);
      if(this.msel){
        this.defaultStatistic = 1;
      }else{
        this.defaultStatistic = 0;
      }
    },
    csvout(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            year:this.year,
            reportType:this.rpt,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.rpt=='1'){
          params.unitNorm     = this.baseUnit;
          params.coefficient  = this.cctype;
        }
        console.log("downloadEnergyCSVDataWithURL Params",params);
        this.loading = true;

        downloadEnergyCSVDataWithURL('bivale/energy/data/actual/usage/download',params)
        .then((res) => {
            let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata019')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    rptChange(e){
      console.log('rptChange',e);
      this.baseUnit = this.baseUnitNormList[0].no;
      this.cctype = this.conversionCoefficientTypes[0].no;
      this.year = this.yearList[0];
      this.data = [];
      this.columns = [];
    },
    yearChange(e){
       console.log('yearChange',e);
    },
    ccTypeChange(e){
      console.log('ccTypeChange',e);
    },
    baseUnitChange(e){
      console.log('baseUnitChange',e);
    },
    initPage(){
      
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getEnergyActualUsageData(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
                this.baseUnitNormList = res.data.baseUnitNormList;
                this.conversionCoefficientTypes = res.data.conversionCoefficientTypes;
                this.reportTypeList = res.data.reportTypeList;
                this.yearList = res.data.yearList;
                this.monthList =  res.data.monthList;
                this.baseUnit = res.data.baseUnitNormList[0].no;
                this.cctype = res.data.conversionCoefficientTypes[0].no;
                this.rpt = res.data.reportTypeList[0].no;
                this.year = res.data.fiscalYear;
                this.fiscalYear = res.data.fiscalYear;
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    // 表格高度计算
    tableSize() {
        setTimeout(() => {
            let height = this.$refs.main.clientHeight;
            let width = this.$refs.main.clientWidth;
            let tableHeight = height - 80;
            this.tscroll.y = tableHeight;
            this.tscroll.x = width;
            console.log(this.tscroll);
        }, 0);
    },
    search(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            year:this.year,
            reportType:this.rpt,
            defaultStatistic:this.defaultStatistic,
            month:this.month,
        };

        if(this.rpt=='1'){
          params.unitNorm     = this.baseUnit;
          params.coefficient  = this.cctype;
        }

        console.log("GetEnergyActualUsageData Params",params);
        this.loading = true;
        getEnergyActualUsageData(params)
        .then((res) => {
            console.log("GetEnergyActualUsageData Result",res);
            if(res.errorCode == '00'){
              this.columns = res.data.columns;
               
              if(this.rpt=='1'){
                this.data = res.data.energyUsageList;
              }else{
                this.data = res.data.costUsageList;
              }
            } 
            this.tableSize();
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    }
  },
  components: {
    
  },
};
</script>

<style scoped>

#energyActualUsage {
  width: 100%;
  height: 100%;
   padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background-color: #7682ce;
  margin-right: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.main{
    width: 100%;
    height: calc(100% - 140px);
    box-shadow: 0px 3px 8px #e7e6e6;
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

input {
  width: 14%;
}

.footer button {
  margin-left: 15px;
}
</style>


